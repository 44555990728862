import { EMPTY_EQUIPMENT_CODE_IND_MAPPING_MODEL } from "presentation/model/EquipmentCodeIndMapping/EquipmentCodeIndMappingModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: EquipmentCodeIndMappingProvider,
    useTracked: useEquipmentCodeIndMappingTracked
} = createContainer(() => useState(EMPTY_EQUIPMENT_CODE_IND_MAPPING_MODEL), {concurrentMode: true});
export { EquipmentCodeIndMappingProvider, useEquipmentCodeIndMappingTracked };

