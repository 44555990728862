export interface EquipmentCodeIndMappingEntity{
    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    equipmentCode: string,
    equipmentCodeList?: string[] | null,
    equipmentCodeInd: string,
    activeInd: string | null,
    mappingType: string,

    [key: string]: string | boolean | null | Object | undefined
}

export const EMPTY_EQUIPMENT_CODE_IND_MAPPING_ENTITY : EquipmentCodeIndMappingEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    equipmentCode: '',
    equipmentCodeList: [],
    equipmentCodeInd: '',
    activeInd: 'Y',
    mappingType: "EquipmenyCodeIndMappingEntity",
}