import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { EquipmentCodeIndMappingProvider } from "presentation/store/EquipmentCodeIndMapping/EquipmentCodeIndMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import EquipmentCodeIndMappingMaintenance from "presentation/view/section/EquipmentCodeIndMapping/EquipmentCodeIndMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const EquipmentCodeIndMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.EQUIPMENT_CODE_IND_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <EquipmentCodeIndMappingProvider>
                <GridStyles/>
                <EquipmentCodeIndMappingMaintenance/>
            </EquipmentCodeIndMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default EquipmentCodeIndMappingContMain;