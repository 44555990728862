import { EquipmentCodeIndMappingRepoImpl } from "domain/repository/EquipmentCodeIndMapping/EquipmentCodeIndMappingRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useEquipmentCodeIndMappingTracked } from "presentation/store/EquipmentCodeIndMapping/EquipmentCodeIndMappingProvider";
import { EquipmentCodeIndMappingVM } from "presentation/viewModel/EquipmentCodeIndMapping/EquipmentCodeIndMappingVM";
import { useMemo } from "react";

export const useEquipmentCodeIndMappingVM = () => {
    const [, setEquipmentCodeIndMappingState] = useEquipmentCodeIndMappingTracked();
    const equipmentCodeIndMappingVM = useMemo(() =>
        EquipmentCodeIndMappingVM({
            dispatch: [setEquipmentCodeIndMappingState],
            equipmentCodeIndMappingRepo: EquipmentCodeIndMappingRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setEquipmentCodeIndMappingState])

    return equipmentCodeIndMappingVM
}